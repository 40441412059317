import { resetPackageType } from './seating_chart_filter'
import PriceFilter from './price_filter.vue'
import VueLoader from '../default/vue-loader'

let pkg_types = []

let checker = (categories, pkg_types) => pkg_types.every(pkg_type => categories.includes(pkg_type))

const addVueListener = function () {
  VueLoader.loadVueComponent(PriceFilter, document.getElementById('seating_chart_price'))
}

const getFirstLastPackages = () => {
  let elems = []
  Array.from(document.querySelectorAll('.product-item')).forEach((elem) => {
    elem.classList.remove('first')
    elem.classList.remove('last')
    document.querySelector('.packageaccess .group').classList.remove('single-pkg')
    if(document.querySelector('.rigtside')){
      document.querySelector('.rigtside').classList.remove('single-pkg')
    } else {
      document.querySelector('.rightside').classList.remove('single-pkg')
    }

    if(!$(elem).parent().hasClass('pkg-hidden')) {
      elems.push(elem)
    }
  })

  let first = elems[0]
  let last = elems[elems.length -1]

  if(first && last) {
    if (first == last) {
      document.querySelector('.packageaccess .group').classList.add('single-pkg')
      document.querySelector('.rigtside').classList.add('single-pkg')
      if (screen.width <= 768)
        last.classList.add('last')
      else
        first.classList.add('first')
    } else {
      first.classList.add('first')
      last.classList.add('last')
    }
  }
}


const hideAllPackages = () => {
  $('.rigtside .product-item').each(function () {
    $(this).parent().addClass('pkg-hidden')
  })
  $('.packageaccess .product-item').each(function () {
    $(this).parent().addClass('pkg-hidden')
  })
}

const disableSections = () => {
  const selections = Array.from(document.querySelectorAll('.leftsidemap [data-toggle=svg-tooltip]'))
  selections.forEach((elem) => {
    $(elem).css('fill', '#ccc')
    $(elem).addClass('disableClick')
  })
}

const filterPackagesRangeSlider = (minVal, maxVal) => {
  hideAllPackages()
  disableSections()

  Array.from(document.querySelectorAll('.product-item')).forEach((elem) => {
    let categories = elem.getAttribute('data-category-names').split(',')
    let price = parseInt(elem.getAttribute('data-price'))

    if (checker(categories, pkg_types) && (minVal <= price && maxVal >= price)) {
      elem.parentElement.classList.remove('pkg-hidden')
    }
  })
  getFirstLastPackages()
  showHideNoPackagesMsg()
}

const displaySelectedFilter = (selectedFilter) => {
  showPackageAccess()
  if (selectedFilter.hasClass('selected')) {
    selectedFilter.removeClass('selected')
  } else {
    selectedFilter.addClass('selected')
  }

  pkg_types = []
  let data_categories = []

  $('.filters_wrapper .navfilter button[data-filter]').each(function () {
    let pkg_type = $(this).attr('data-filter')
    if (pkg_type) {
      if ($(this).hasClass('selected')) {
        pkg_types.push(pkg_type)
      } else {
        let index = pkg_types.indexOf(pkg_type)
        if (index > 0) {
          pkg_types.splice(index, 1)
        }
      }
    }
    pkg_types = Array.from(new Set(pkg_types))
  })

  if(pkg_types.length == 0) {
    resetPackageType()
    enableDisableFilters(data_categories)
    addVueListener()
  } else {
    hideAllPackages()
    filterPackages(pkg_types)
    Array.from(document.querySelectorAll('.product-item')).forEach((elem) => {
      if (!($(elem).parent().hasClass('pkg-hidden'))) {
        let categories = elem.getAttribute('data-category-names').split(',')
        data_categories.push(categories)
      }
    })
    data_categories = data_categories.flat()
    data_categories = Array.from(new Set(data_categories))

    enableDisableFilters(data_categories)
    addVueListener()
  }
  getFirstLastPackages()
  showHideNoPackagesMsg()
}

const filterPackages = (pkg_types) => {
  Array.from(document.querySelectorAll('.product-item')).forEach((elem) => {
    let categories = elem.getAttribute('data-category-names').split(',')
    if (checker(categories, pkg_types)) {
      elem.parentElement.classList.remove('pkg-hidden')
    }
  })
}

const enableDisableFilters = (data_categories) => {
  const filters = Array.from(document.querySelectorAll('.filters_wrapper .navfilter button[data-filter]'))
  filters.forEach((elem) => {
    let data_filter = elem.getAttribute('data-filter')
    if (data_categories.length == 0) {
      elem.classList.remove('disabled')
    } else {
      if (data_categories.includes(data_filter))
        elem.classList.remove('disabled')
      else
        elem.classList.add('disabled')
    }
  })
}

const showPackageAccess = () => {
  if(document.getElementsByClassName('packageaccess_msg')[0] && document.getElementsByClassName('packageaccess')[0] ){
    document.getElementsByClassName('packageaccess_msg')[0].classList.add('hide')
    document.getElementsByClassName('packageaccess')[0].classList.remove('hide')
  }
}

const showHideNoPackagesMsg = () => {
  let pkgs = document.querySelectorAll('.group-package')
  let hidden_pkgs = document.querySelectorAll('.group-package.pkg-hidden')

  if (hidden_pkgs.length == pkgs.length && document.getElementsByClassName('no_packages_msg')[0] &&
    document.getElementsByClassName('packageaccess')[0]) {
    document.getElementsByClassName('no_packages_msg')[0].classList.remove('hide')
    document.getElementsByClassName('packageaccess')[0].classList.add('hide')
  } else if (document.getElementsByClassName('no_packages_msg')[0] &&
      document.getElementsByClassName('packageaccess')[0]) {
    document.getElementsByClassName('no_packages_msg')[0].classList.add('hide')
    document.getElementsByClassName('packageaccess')[0].classList.remove('hide')
  } else {
    document.getElementsByClassName('packageaccess')[0].classList.remove('hide')
  }
}

$(document)
  .on('change', '.slider__min-slider', function () {
    showPackageAccess()
    $('.min-slider').blur()
    $('.max-slider').blur()

    const minVal = parseInt($(this).val())
    const maxVal = parseInt($('.slider__max-slider').val())

    filterPackagesRangeSlider(minVal, maxVal)
  })

  .on('keyup', '.min-slider', function (event) {
    showPackageAccess()
    if (event.keyCode == 13) {
      const minVal = parseInt($(this).val().slice(1))
      const maxVal = parseInt($('.slider__max-slider').val())
      $('.min-slider').blur()
      $('.max-slider').blur()

      filterPackagesRangeSlider(minVal, maxVal)
    }
  })

  .on('change', '.slider__max-slider', function () {
    showPackageAccess()
    $('.min-slider').blur()
    $('.max-slider').blur()

    const minVal = parseInt($('.slider__min-slider').val())
    const maxVal = parseInt($(this).val())

    filterPackagesRangeSlider(minVal, maxVal)
  })

  .on('keyup', '.max-slider', function (event) {
    showPackageAccess()
    if (event.keyCode == 13) {
      const minVal = parseInt($('.slider__min-slider').val())
      const maxVal = parseInt($(this).val().slice(1))
      $('.min-slider').blur()
      $('.max-slider').blur()

      filterPackagesRangeSlider(minVal, maxVal)
    }
  })

  .on('click', '.filters .filter-label', function () {
    displaySelectedFilter($(this))
  })

  .on('change', '.sort-by-filter', function () {
    if($(this).val()) {
      showPackageAccess()
      getFirstLastPackages()
    }
  })

document.addEventListener('turbo:load', () => {
  addVueListener()
})

Array.from(document.querySelectorAll('.seatingChartPackage')).forEach((elem) => {
  let whatsIncluded = elem.querySelector('.whats-included')

  if(whatsIncluded){
    whatsIncluded.addEventListener('mouseover', () => {
      let rigtside_top = document.getElementsByClassName('rigtside')[0].getBoundingClientRect().top
      let elem_top = elem.getBoundingClientRect().top
      let diff = elem_top - rigtside_top

      if (diff <=  21)
        elem.querySelector('.tooltip').classList.add('top_pos')
      else
        elem.querySelector('.tooltip').classList.remove('top_pos')
    })
  }

  elem.addEventListener('wheel', () => {
    let rigtside_top = document.getElementsByClassName('rigtside')[0].getBoundingClientRect().top
    let elem_top = elem.getBoundingClientRect().top
    let diff = elem_top - rigtside_top

    if (diff <=  21)
      elem.querySelector('.tooltip').classList.add('top_pos')
    else
      elem.querySelector('.tooltip').classList.remove('top_pos')
  })
})

export {displaySelectedFilter}
